import React from "react";
import {PageTemplate} from "../PageTemplate";

export const OpenAI = (props) => {

    const sections = [
        {
            header: "Expert Fine-Tuning",
            body: "We specialize in meticulously training OpenAI models like GPT-3.5 to excel in tasks such as classification and Q&A, leveraging our three years of pioneering experience to optimize performance and navigate complexities."
        },
        {
            header: "Seamless Integration and Reliable Performance",
            body: "Our team excels in integrating GPT-3.5 into diverse applications, ensuring smooth functionality and peak efficiency, complemented by thorough stress-testing to ensure consistent, trustworthy AI outcomes."
        },
        {
            header: "Enhanced with RAG Technology",
            body: "By incorporating Retrieval-Augmented Generation (RAG), we elevate the capabilities of GPT-3.5, enriching its responses with information retrieved from a vast dataset. This fusion of generative power and targeted retrieval ensures our models deliver not only relevant but also highly informed outputs."
        }
    ]

    return (
        <PageTemplate
            title={"Open AI"}
            sections={sections}
            imageUrl={""} //TODO
            {...props}
        />
    )
}