export const common = {
    contactUs: "We want you on board and we're here for all the steps that will take your business into tomorrow. Our team will be by your side helping turn goals into accomplishments."
}

export const home = {
    body1: "A leading mobile app development company, focused on creating customized Android or iOS applications that will be just right for you! The future of technology is here and we can help your business get a head start.",
    body2: "We are a focused team of industry experts who understand how technology can enable business success. Each of our team members bring a wealth of experience to meet every need in the technical space. It is our mission to affordably bring quality applications to every business."
}

export const about = {
    body1: "Snyder Consulting is a technology consulting firm that puts the needs of its clients first. We believe in doing good work for our community, getting better software out there and enabling fellow businesses to be more successful with technology.",
    body2: "Our team is made up of the best people, who are empowered to do their best work for our clients. We strive to create meaningful business relationships with our clients through transparency and collaboration."
}