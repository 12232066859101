import {extendTheme} from "@chakra-ui/react";

const theme = extendTheme({
    config: {
        initialColorMode: "light",
        useSystemColorMode: false,
    },
    colors: {
        midnightOil: "#0E1220",
        businessBlue: "#1D2C44",
        sportJacket: "#3E5B75",
        ideaYellow: {
            100: "#F9D27D",
            200: "#E3B85B"
        },
        eggshell: {
            100: "#FEFCFA",
            200: "#FDF4E4",
            300: "#F4E9D9",
            400: "#CFBB9E"
        },
        white: "#ffffff",
        peach: "#F3A883",
        red: {
            100: "#C14E2D",
            200: "#9D3618"
        },
        teal: "#4DB0A9"
    }
})

export { theme }