import {Text} from "@chakra-ui/react";

export const Typography = () => {

}

export const H1 = props => {
    return <Text
        style={{
            fontSize: "90px",
            lineHeight: "90px",
            fontFamily: 'Bebas Neue',
        }}
        color={"businessBlue"}
        {...props}/>
}

export const H2 = props => {
    return <Text
        style={{
            fontSize: "48px",
            lineHeight: "48px",
            fontFamily: 'Bebas Neue',
        }}
        color={"businessBlue"}
        {...props}/>
}

export const H3 = props => {
    return <Text
        style={{
            fontSize: "32px",
            lineHeight: "32px",
            fontFamily: 'Bebas Neue',
        }}
        color={"businessBlue"}
        {...props}/>
}

export const H4 = props => {
    return <Text
        style={{
            fontSize: "24px",
            lineHeight: "24px",
            fontFamily: 'Bebas Neue',
        }}
        color={"businessBlue"}
        {...props}/>
}

export const H5 = props => {
    return <Text
        style={{
            fontSize: "20px",
            lineHeight: "20px",
            fontFamily: 'Bebas Neue',
        }}
        color={"businessBlue"}
        {...props}/>
}

export const H6 = props => {
    return <Text
        style={{
            fontSize: "16px",
            lineHeight: "16px",
            fontFamily: 'Bebas Neue',
        }}
        color={"businessBlue"}
        {...props}/>
}

export const Body = props => {
    return <Text
        style={{
            fontSize: "16px",
            lineHeight: "24px",
            fontFamily: 'Roboto',
        }}
        color={"businessBlue"}
        {...props}/>
}

export const Caption = props => {
    return <Text
        style={{
            fontSize: "12px",
            lineHeight: "12px",
            fontFamily: 'Roboto',
        }}
        color={"businessBlue"}
        {...props}/>
}