import React from 'react';
import {ChakraProvider} from '@chakra-ui/react';

import {theme} from "./Theme";

import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {Contact} from "./pages/Contact";
import {Home} from "./pages/Home";
import {Planning} from "./pages/services/Planning";
import {About} from "./pages/About";
import ScrollToTop from "./components/ScrollToTop";
import {Development} from "./pages/services/Development";
import {Support} from "./pages/services/Support";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {OpenAI} from "./pages/services/OpenAI";

function App() {
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: "snyder-consulting-website.firebaseapp.com",
        projectId: "snyder-consulting-website",
        storageBucket: "snyder-consulting-website.appspot.com",
        messagingSenderId: "366548796281",
        appId: "1:366548796281:web:7b93b216d6decae9ede5a8",
        measurementId: "G-FQFRLVTJ9N"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    const WebFont = require('webfontloader');

    WebFont.load({
        google: {
            families: ['Roboto', 'Bebas Neue']
        }
    });

    return (
        <ChakraProvider theme={theme}>
            <Router>
                <ScrollToTop>
                    <Switch>
                        <Route exact path="/about">
                            <About analytics={analytics}/>
                        </Route>
                        {/*<Route path="/blog">*/}
                        {/*    <VStack>*/}
                        {/*        <SCHeader path={"/blog"}/>*/}
                        {/*        <Text>Blog</Text>*/}
                        {/*    </VStack>*/}
                        {/*</Route>*/}
                        <Route exact path="/contact">
                            <Contact analytics={analytics}/>
                        </Route>
                        <Route exact path="/">
                            <Home analytics={analytics}/>
                        </Route>
                        <Route exact path="/services/planning">
                            <Planning analytics={analytics}/>
                        </Route>
                        <Route exact path="/services/development">
                            <Development analytics={analytics}/>
                        </Route>
                        <Route exact path="/services/support">
                            <Support analytics={analytics}/>
                        </Route>
                        <Route exact path="/services/open-ai">
                            <OpenAI analytics={analytics}/>
                        </Route>
                        <Redirect to="/"/>
                    </Switch>
                </ScrollToTop>
            </Router>
        </ChakraProvider>
    );
}

export default App;
