import {HStack, Text} from "@chakra-ui/react";
import {ArrowUpIcon, LogoIcon} from "./Icons";
import {ShrinkBox} from "./ShrinkBox";

export const SCFooter = (props) => {
    return (
        <ShrinkBox {...props}>
            <LogoIcon/>
            <HStack>
                <Text
                    paddingY={"16px"}
                    style={{
                        fontFamily: 'Roboto',
                        fontSize: "12px",
                        lineHeight: "24px",
                        fontWeight: "normal",
                        color: "businessBlue",
                    }}>
                    Copyright {new Date().getFullYear()} | Snyder Consulting
                </Text>
                {/*TODO Enable this after repo is public*/}
                {/*<GithubIcon cursor="pointer"/>*/}
            </HStack>
            {!props.isMobile && (
                <HStack cursor="pointer">
                    <Text
                        onClick={() => {
                            window.scroll({
                                top: 0,
                                behavior: 'smooth'
                            });
                        }}
                        style={{
                            fontFamily: 'Roboto',
                            fontSize: "12px",
                            lineHeight: "24px",
                            fontWeight: "bold",
                            color: "businessBlue",
                        }}
                        as="u"
                    >
                        Back to Top
                    </Text>
                    <ArrowUpIcon/>
                </HStack>
            )}

        </ShrinkBox>
    )
}