import {Menu, SCHeader} from "../components/SCHeader";
import {createStandaloneToast, Fade, VStack} from "@chakra-ui/react";
import {SCButton} from "../components/SCButton";
import {SCFooter} from "../components/SCFooter";
import {SCFormInput} from "../components/SCFormInput";
import React, {useEffect, useState} from "react";
import {Body, H2} from "../components/Typography";
import {useWindowSize} from "../utils/UseWindowSize";
import {logEvent} from "firebase/analytics";

export const Contact = (props) => {

    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const [nameError, setNameError] = useState(null)
    const [emailError, setEmailError] = useState(null)
    const [messageError, setMessageError] = useState(null)

    const [width] = useWindowSize()
    const [menuOpen, setMenuOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const padding = width > 1200 ? (width - 1140) / 2 : 24

    const baseUrl = "/api/email"

    const toast = createStandaloneToast()

    useEffect(() => {
        if (name !== "") {
            setNameError(null)
        }
    }, [name])

    useEffect(() => {
        if (email !== "") {
            setEmailError(null)
        }
    }, [email])

    useEffect(() => {
        if (message !== "") {
            setMessageError(null)
        }
    }, [message])

    useEffect(() => {
        logEvent(props.analytics, "page_view", {page_location: "/contact", page_title: "Contact"})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function sendFormEmail() {
        let isValid = true;

        if (name === "") {
            isValid = false
            setNameError("Name required.")
        }
        if (email === "") {
            isValid = false
            setEmailError("Email required.")
        }
        if (message === "") {
            isValid = false
            setMessageError("Message required.")
        }

        if (isValid) {
            (async function () {
                toast.closeAll()
                setIsLoading(true)

                let isSuccess

                await fetch(`${baseUrl}?name=${name}&phone=${phone}&email=${email}&message=${message}`)
                    .then((response) => {
                        isSuccess = response.ok

                        return response.json()
                    })
                    .then((data) => {
                        if (isSuccess === true) {
                            console.log("Form submitted successfully.")
                            toast({
                                title: 'Success!',
                                description: "Your message has been delivered.",
                                status: 'success',
                                duration: 5000,
                                isClosable: false,
                            })
                        } else {
                            console.log(data.error.message)
                            toast({
                                title: 'Error.',
                                description: data.text,
                                status: 'error',
                                duration: 5000,
                                isClosable: false,
                            })
                        }
                    })
                    .catch((reason) => {
                        console.log("Form failed to submit due to internal error.")
                        toast({
                            title: 'Error.',
                            description: "Something went wrong, please try again.",
                            status: 'error',
                            duration: 5000,
                            isClosable: false,
                        })
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            })();
        }
    }

    const isMobile = width <= 1200

    return menuOpen ? (
        <Fade in={menuOpen} animateOpacity>
            <Menu path={"/contact"} setMenuOpen={setMenuOpen}/>
        </Fade>
    ) : (
        <VStack minH={"100vh"} h={"100%"} bg="eggshell.100" px={padding + "px"} spacing={"24px"}>
            <SCHeader path={"/contact"} isMobile={isMobile} setMenuOpen={setMenuOpen}/>
            <H2 pt={"48px"} alignSelf={"start"}>Contact Us</H2>
            <Body color={"sportJacket"} alignSelf={"start"}>How can we help your business be more successful with
                technology?
            </Body>
            <VStack w={"100%"} alignItems={"start"} pb={"48px"} flex={1}>
                <SCFormInput title={"Full Name"} error={nameError} value={name} onChange={setName}/>
                <SCFormInput title={"Phone Number"} value={phone} onChange={setPhone}/>
                <SCFormInput title={"Email"} error={emailError} value={email} onChange={setEmail}/>
                <SCFormInput title={"Message"} error={messageError} value={message} onChange={setMessage}
                             isTall={!isMobile} autoHeight={isMobile}/>
                <SCButton enabled={!isLoading} text={"Send"} disabledText={"Sending..."} style={{marginTop: "32px"}}
                          onClick={() => sendFormEmail()}/>
                <VStack w={"100%"} pt={"48px"} alignItems={"center"}>
                    <Body as="a" href={"tel:7012371610"} color={"sportJacket"}>701-237-1610</Body>
                    <Body as="a" href={"mailto:info@snyderconsulting.tech"}
                          color={"sportJacket"}>info@snyderconsulting.tech</Body>
                </VStack>
            </VStack>
            <SCFooter style={{marginTop: "0px"}} isMobile={isMobile}/>
        </VStack>
    )
}