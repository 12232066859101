import React from "react";
import {PageTemplate} from "../PageTemplate";

export const Development = (props) => {
    const sections = [
        {
            header: "Android & iOS",
            body: "Some agencies prefer to save time by developing a single app that works on multiple platforms. But we know how important it is for your users and customers, so instead of giving them what they already have - which might not be perfect or fully utilize their devices' capabilities- our team takes an approach where each platform gets its own beautiful mobile application with top notch features tailored specifically towards those unique needs!"
        },
        {
            header: "Web Apps",
            body: "Your business deserves a professional, modern website that will reflect your organization's best self. We design websites with the latest technologies and follow industry trends in order to deliver functional web apps for customers worldwide!"
        },
        {
            header: "Process Automation",
            body: "Technology is changing the way we do business and there are plenty of opportunities for automation. From connecting digital tools in an effortless manner to crafting custom workflows, our team will find a solution that fits your needs perfectly!"
        }
    ]
    return (
        <PageTemplate
            title={"Development"}
            sections={sections}
            imageUrl={""} //TODO
            {...props}
        />
    )
}