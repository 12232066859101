import {AppDevIcon} from "../Icons";
import {SCCard} from "../SCCard";

export const DevelopmentCard = (props) => {
    return (
        <SCCard title={"Development"}
                body={"We craft native apps across Android, iOS & Web that are functional and visually appealing."}
                {...props}
        >
            <AppDevIcon width={props.isMobile ? null : "332"} height={"220"}/>
        </SCCard>
    )
}