import {TechAnalysisIcon} from "../Icons";
import {SCCard} from "../SCCard";

export const SupportCard = (props) => {
    return (
        <SCCard title={"Support"}
                body={"Releasing a great product is only the beginning, we'll help you keep it going."}
                {...props}
        >
            <TechAnalysisIcon width={props.isMobile ? null : "158"} height={"150"}/>
        </SCCard>
    )
}