import {HStack, Link, Text, VStack} from "@chakra-ui/react";
import {CloseIcon, FacebookIcon, LogoStackedIcon, MenuIcon, YouTubeIcon} from "./Icons";
import {ShrinkBox} from "./ShrinkBox";

const pages = [
    {name: "home", path: "/"},
    {name: "about", path: "/about"},
    // {name: "blog", path: "/blog"},
    {name: "contact", path: "/contact"},
]

export const SCHeader = (props) => {

    return (
        <>
            <ShrinkBox boxShadow={"md"} py={"24px"} isMobile={props.isMobile}>
                <LogoStackedIcon cursor="pointer"/>
                {
                    !props.isMobile && (
                        <>
                            <HStack spacing="40px">
                                {pages.map((page) => {
                                    return (
                                        <NavItem
                                            key={page.path}
                                            selected={props.path === page.path}
                                        >
                                            {page}
                                        </NavItem>
                                    )
                                })}
                            </HStack>
                            <HStack>
                                <FacebookIcon cursor="pointer"/>
                                <YouTubeIcon cursor="pointer"/>
                            </HStack>
                        </>
                    )
                }
                {
                    props.isMobile && (
                        <MenuIcon onClick={() => {
                            props.setMenuOpen(true)
                        }}/>
                    )
                }
            </ShrinkBox>
        </>

    )
}

export const Menu = (props) => {
    return (
        <VStack width={"100%"} minH={"100vh"} h={"100%"} padding={"24px"} alignItems={"center"}
                justifyContent={"space-between"}>
            <VStack width={"100%"} height={"100%"}>
                <CloseIcon onClick={() => {
                    props.setMenuOpen(false)
                }} style={{alignSelf: "end"}}/>
                <VStack paddingTop={"72px"} spacing={"40px"}>
                    {pages.map((page) => {
                        return (
                            <NavItem
                                key={page.path}
                                selected={props.path === page.path}
                                onClick={() => {
                                    props.setMenuOpen(false)
                                }}
                            >
                                {page}
                            </NavItem>
                        )
                    })}
                </VStack>
            </VStack>
            <HStack height={"100%"} paddingBottom={"72px"}>
                <FacebookIcon cursor="pointer"/>
                <YouTubeIcon cursor="pointer"/>
            </HStack>
        </VStack>
    )
}

const NavItem = props => {
    return (
        <Text paddingBottom={props.selected ? "3px" : "0px"}
              as={props.selected ? "u" : ""}
              onClick={props.onClick}
              style={{
                  fontFamily: 'Bebas Neue',
                  fontSize: "20px",
                  lineHeight: "24px",
                  fontWeight: 400,
                  color: "businessBlue",
                  textDecorationThickness: "1px",
                  textUnderlineOffset: "2px",
                  cursor: "pointer"
              }}
        >
            <Link href={props.children.path}>
                {props.children.name}
            </Link>
        </Text>
    )
}