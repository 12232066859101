import React from "react";
import {PageTemplate} from "../PageTemplate";

export const Planning = (props) => {
    const sections = [
        {
            header: "Consulting",
            body: "Connecting your business to the future of technology is our job. We make sure you don't have any surprises on that front, and we guide every step along this journey with care so it's as easy for you as possible. We're here when you need us most - whether it's bringing an idea into reality or moving something existing into new territory."
        },
        {
            header: "Architecture",
            body: "While we do offer development services, we understand that you may choose to work with a development agency. We want to get you started in the right direction by providing you with a list of features and how they will be implemented. This will set your project up for success whether you choose to continue working with us or not."
        },
        {
            header: "MVP",
            body: "We're all about creating products that solve real-world problems. And while it's important to have big dreams, we find the best way forward is by taking small steps - one at a time! The first of these will be an MVP or \"Minimum Viable Product\". The MVP process helps you identify key features and capabilities, which will help shape the final version of your software so it meets those requirements while also meeting timeframes set out for your go-to-market strategy."
        }
    ]
    return (
        <PageTemplate
            title={"Planning"}
            sections={sections}
            imageUrl={""} //TODO
            {...props}
        />
    )
}