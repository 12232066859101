import {VStack} from "@chakra-ui/react";
import {Body, H1} from "./Typography";
import {useState} from "react";

export const HighlightValue = props => {
    const [progress, setProgress] = useState(0)

    const updateCount = () => {
        if (progress < 100) {
            setProgress(progress + 1)
        }
    };

    const formattedValue = (value, progress, decimals) => {
        return ((value * progress / 100)).toLocaleString('en', {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        })
    }

    const autoFormattedValue = (value, progress, decimals) => {
        const snapshotValue = value * progress / 100

        if (snapshotValue >= 1000000) {
            //Million
            return ((snapshotValue / 1000000)).toLocaleString('en', {
                minimumFractionDigits: snapshotValue % 1000000 === 0 ? 0 : 2,
                maximumFractionDigits: snapshotValue % 1000000 === 0 ? 0 : 2
            }) + "M"
        } else if (snapshotValue >= 1000) {
            //Thousand
            return ((snapshotValue / 1000)).toLocaleString('en', {
                minimumFractionDigits: snapshotValue % 1000 === 0 ? 0 : 2,
                maximumFractionDigits: snapshotValue % 1000 === 0 ? 0 : 2
            }) + "K"
        } else {
            return ((snapshotValue)).toLocaleString('en', {
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals
            })
        }
    }

    const decimals = (props.decimalPoints && props.animate ? props.decimalPoints : 0)

    if (props.animate) {
        setTimeout(updateCount, 20);
    }

    return (
        <VStack minW={!props.isMobile ? "200px" : null}>
            <H1 color={"ideaYellow.200"}>
                {props.autoConvert && autoFormattedValue(props.value, progress, decimals)}
                {!props.autoConvert && formattedValue(props.value, progress, decimals)}
                {props.suffix}
            </H1>
            <Body color={"sportJacket"}>
                {props.label}
            </Body>
        </VStack>
    )
}