import {Menu, SCHeader} from "../components/SCHeader";
import {Box, Fade, HStack, VStack} from "@chakra-ui/react";
import {SCButton} from "../components/SCButton";
import {SCFooter} from "../components/SCFooter";
import {Body, H1, H2, H4} from "../components/Typography";
import {useHistory} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {LightbulbIcon, SnyderConsultingCircularIcon} from "../components/Icons";
import {useWindowSize} from "../utils/UseWindowSize";
import {PlanningCard} from "../components/Cards/PlanningCard";
import {DevelopmentCard} from "../components/Cards/DevelopmentCard";
import {SupportCard} from "../components/Cards/SupportCard";
import {common, home} from "../Copy";
import {logEvent} from "firebase/analytics";
import {OpenAICard} from "../components/Cards/OpenAICard";

export const Home = (props) => {
    const history = useHistory()
    const navigateToAboutPage = useCallback(() => history.push('/about'), [history]);
    const navigateToContactPage = useCallback(() => history.push('/contact'), [history]);
    const navigateToPlanningPage = useCallback(() => history.push('/services/planning'), [history]);
    const navigateToDevelopmentPage = useCallback(() => history.push('/services/development'), [history]);
    const navigateToSupportPage = useCallback(() => history.push('/services/support'), [history]);
    const navigateToOpenAIPage = useCallback(() => history.push('/services/open-ai'), [history]);
    const [width] = useWindowSize()

    const [menuOpen, setMenuOpen] = useState(false)

    const padding = (width - 1140) / 2

    useEffect(() => {
        logEvent(props.analytics, "page_view", {page_location: "/", page_title: "Home"})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const DesktopLayout = () => {
        return (
            <VStack minH={"100vh"} h={"100%"} bg="eggshell.100" px={padding + "px"}>
                <SCHeader path={"/"}/>
                <HStack alignSelf={"start"} alignItems={"start"}>
                    <VStack spacing={"24px"} pt={"208px"} alignSelf={"start"} alignItems={"start"}>
                        <H4 alignSelf={"start"} color={"sportJacket"}>Snyder Consulting</H4>
                        <Box backgroundColor={"businessBlue"} height={"1px"} width={"42px"}/>
                        <H1 whiteSpace={"nowrap"}>Snyder Consulting</H1>
                        <Body color={"sportJacket"}>
                            {home.body1}
                        </Body>
                        <SCButton text={"Learn More"} onClick={() => navigateToAboutPage()}/>
                    </VStack>
                    <LightbulbIcon width={"577px"} height={"707px"}/>
                </HStack>
                <HStack w={"100vw"} paddingTop={"48px"} spacing={"24px"}>
                    <VStack
                        alignSelf={"start"}
                        alignItems={"start"}
                        backgroundImage={`linear-gradient(to bottom, #F4E9D9 383px, #FEFCFA 0%)`}
                        paddingStart={padding + "px"}
                        spacing={"42px"}
                    >
                        <Box
                            left={0}
                            position={"absolute"}
                            backgroundColor={"businessBlue"}
                            height={"2px"}
                            width={padding + "px"}
                        />
                        <Box
                            style={{marginTop: "0"}}
                            backgroundColor={"businessBlue"}
                            height={"2px"}
                            width={"50%"}
                        />
                        <Box w={"100%"}
                             display={"flex"}
                             justifyContent={"flex-end"}
                             style={{marginTop: "51px"}}
                        >
                            <SnyderConsultingCircularIcon/>
                        </Box>
                        <H4 color={"sportJacket"}>About</H4>
                        <Box backgroundColor={"businessBlue"} height={"1px"} width={"42px"}/>
                        <H2 width={"330px"}>Helping businesses be more successful with technology</H2>
                        <SCButton text={"Learn More"} onClick={() => navigateToAboutPage()}/>
                    </VStack>
                    <Body display={"flex"}
                          color={"sportJacket"}
                          paddingTop={"383px"}
                          style={{marginRight: padding + "px"}}
                          minW={"400px"}
                    >
                        {home.body2}
                    </Body>
                </HStack>
                <H2 paddingTop={"187px"}>Services</H2>
                <VStack paddingTop={"38px"} spacing={"24px"}>
                    <OpenAICard onClick={navigateToOpenAIPage}/>
                    <HStack spacing="24px">
                        <PlanningCard onClick={navigateToPlanningPage}/>
                        <DevelopmentCard onClick={navigateToDevelopmentPage}/>
                        <SupportCard onClick={navigateToSupportPage}/>
                    </HStack>
                </VStack>
                <VStack spacing={"24px"}>
                    <H2 paddingTop={"185px"}>Ready to Work Together?</H2>
                    <Body width={"750px"} color={"sportJacket"}>
                        {common.contactUs}
                    </Body>
                    <SCButton style={{marginBottom: "162px"}} text={"Contact Us"}
                              onClick={() => navigateToContactPage()}/>
                </VStack>

                <SCFooter/>
            </VStack>
        )
    }

    const MobileLayout = () => {
        return menuOpen ? (
            <Fade in={menuOpen} animateOpacity>
                <Menu path={"/"} setMenuOpen={setMenuOpen}/>
            </Fade>
        ) : (
            <VStack spacing={"40px"}>
                <SCHeader isMobile setMenuOpen={setMenuOpen}/>
                <VStack px={"24px"} spacing={"40px"} alignItems={"start"} w={"100%"}>
                    <HStack w={"100%"} alignItems={"end"}>
                        <VStack height={"100%"} alignItems={"start"} spacing={"40px"}>
                            <H4 alignSelf={"start"} color={"sportJacket"}>Snyder Consulting</H4>
                            <Box backgroundColor={"businessBlue"} height={"1px"} width={"42px"}/>
                        </VStack>
                        <LightbulbIcon style={{maxWidth: "182px"}}/>
                    </HStack>
                    <H2>Snyder Consulting</H2>
                    <Body color={"sportJacket"}>
                        {home.body1}
                    </Body>
                    <SCButton text={"Learn More"} onClick={() => navigateToAboutPage()}/>
                </VStack>
                <VStack
                    style={{marginTop: "80px"}}
                    paddingLeft={"24px"}
                    alignSelf={"start"}
                    alignItems={"start"}
                    backgroundImage={`linear-gradient(to bottom, #F4E9D9 383px, #FEFCFA 0%)`}
                    spacing={"42px"}
                >
                    <Box
                        left={0}
                        position={"absolute"}
                        backgroundColor={"businessBlue"}
                        width={"24px"}
                        height={"2px"}
                    />
                    <Box
                        style={{marginTop: "0"}}
                        backgroundColor={"businessBlue"}
                        height={"2px"}
                        width={"50%"}
                    />
                    <Box
                        w={"100%"}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        style={{marginTop: "0px"}}
                        position={"relative"}
                        top={"51px"}
                    >
                        <SnyderConsultingCircularIcon/>
                    </Box>
                    <H4 color={"sportJacket"}>About</H4>
                    <Box backgroundColor={"businessBlue"} height={"1px"} width={"42px"}/>
                    <H2 maxWidth={"330px"}>Helping businesses be more successful with technology</H2>
                </VStack>
                <VStack px={"24px"} spacing={"40px"} alignItems={"start"}
                >
                    <Body display={"flex"}
                          color={"sportJacket"}
                    >
                        {home.body2}
                    </Body>
                    <SCButton text={"Learn More"} onClick={() => navigateToAboutPage()}/>
                    <VStack spacing="24px" w={"100%"}>
                        <OpenAICard onClick={navigateToOpenAIPage} isMobile/>
                        <PlanningCard onClick={navigateToPlanningPage} isMobile/>
                        <DevelopmentCard onClick={navigateToDevelopmentPage} isMobile/>
                        <SupportCard onClick={navigateToSupportPage} isMobile/>
                    </VStack>
                    <H2 paddingTop={"32px"}>Ready to Work Together?</H2>
                    <Body color={"sportJacket"}>
                        {common.contactUs}
                    </Body>
                    <SCButton text={"Contact Us"}
                              onClick={() => navigateToContactPage()}/>
                </VStack>
                <SCFooter style={{marginTop: "72px"}} isMobile/>
            </VStack>
        )
    }

    return width > 1200 ? DesktopLayout() : MobileLayout()
}