import {Box, Fade, Image, VStack} from "@chakra-ui/react";
import {Menu, SCHeader} from "../components/SCHeader";
import {Body, H2, H3} from "../components/Typography";
import React, {useCallback, useEffect, useState} from "react";
import {SCFooter} from "../components/SCFooter";
import {useWindowSize} from "../utils/UseWindowSize";
import {logEvent} from "firebase/analytics";
import {common} from "../Copy";
import {SCButton} from "../components/SCButton";
import {useHistory} from "react-router-dom";

export const PageTemplate = props => {

    const history = useHistory()
    const navigateToContactPage = useCallback(() => history.push('/contact'), [history]);

    const [isImageError, setIsImageError] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [width] = useWindowSize()

    const isMobile = width <= 1200

    const padding = isMobile ? 24 : (width - 1140) / 2

    useEffect(() => {
        logEvent(props.analytics, "page_view", {page_location: `/services/${props.title}`, page_title: props.title})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return menuOpen ? (
        <Fade in={menuOpen} animateOpacity>
            <Menu setMenuOpen={setMenuOpen}/>
        </Fade>
    ) : (
        <VStack minH={"100vh"} h={"100%"} bg="eggshell.100" px={padding + "px"} spacing={"24px"}>
            <SCHeader isMobile={isMobile} setMenuOpen={setMenuOpen}/>
            <VStack alignItems={"start"} flex={1}>
                <H2>{props.title}</H2>
                {
                    isImageError === true &&
                    <Box backgroundColor={"eggshell.300"} width={"100%"} height={"40px"}/>
                }
                {
                    (isImageError === false) &&
                    <Image
                        fit={"cover"}
                        minWidth={"1140px"}
                        height={"400px"}
                        src={props.imageUrl ? props.imageUrl : ""}
                        onError={() => setIsImageError(true)}
                        onLoad={() => setIsImageError(false)}
                    />
                }
                {
                    props.sections.map((section, index) => {
                        return (
                            <VStack key={index} alignItems={"start"} paddingTop={"40px"}>
                                <H3>{section.header}</H3>
                                <Body>{section.body}</Body>
                            </VStack>
                        )
                    })
                }
                <H2 paddingTop={"64px"}>Ready to Work Together?</H2>
                <Body color={"sportJacket"}>
                    {common.contactUs}
                </Body>
                <SCButton text={"Contact Us"}
                          onClick={() => navigateToContactPage()}/>

                {props.children}
            </VStack>
            <SCFooter isMobile={isMobile} style={{marginTop: "72px"}}/>
        </VStack>
    )
}