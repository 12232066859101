import React from "react";
import {PageTemplate} from "../PageTemplate";

export const Support = (props) => {
    const sections = [
        {
            header: "Product Review",
            body: "Now that you’ve built a product, it's time to start analyzing. Our tech specialists will review your app and provide an analysis of the following: User Experience, Design, Usability, Accessibility. We'll score each area for what we like or would change about them before throwing out some ideas based on our findings!"
        },
        {
            header: "Analytics & Monitoring",
            body: "A successful business relies on understanding their customers. We will work closely together to create an analytics plan that helps you make data-driven decisions about how best continue engaging audiences through all stages of the lifecycle, including A/B testing for new features and enhancements suggested by possible problems or opportunities identified during this process"
        },
        {
            header: "Post-Development",
            body: "Whether you worked with us to build your app or website or are coming to our team with an existing product, we are here to help. We offer support on your product after it has gone live in various ways, including review processing, feature enhancements and bug-fixes. Software is a living thing, getting it out to the world is only the beginning. We will guide you through the iterative lifecycle."
        }
    ]
    return (
        <PageTemplate
            title={"Support"}
            sections={sections}
            imageUrl={""} //TODO
            {...props}
        />
    )
}