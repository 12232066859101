import {Box, Text, VStack} from "@chakra-ui/react";
import {ArrowIcon} from "./Icons";

export const SCCard = (props) => {

    return (
        <Box role={"group"} backgroundColor={"white"} boxShadow="base"
             style={{
                 transition: "background-color 300ms ease",
             }}
             _hover={{
                 backgroundColor: "sportJacket",
             }}
             cursor={"pointer"}
             onClick={() => props.onClick && props.onClick()}
             w={props.isMobile ? "100%" : props.width ? props.width : null}
             maxW={!props.isMobile ? props.width ? props.width : "364px" : null}
        >
            <VStack p="16px">
                <Box bg="eggshell.100" w="100%" h="220px" display={"flex"} justifyContent={"center"}
                     alignItems={"center"}>
                    {props.children}
                </Box>
                <Text alignSelf={"start"}
                      style={{
                          fontFamily: 'Bebas Neue',
                          fontSize: "32px",
                          lineHeight: "32px",
                          marginTop: "24px",
                          fontWeight: 500,
                          color: "businessBlue",
                          transition: "color 200ms ease"
                      }}
                      _groupHover={{
                          color: "white",
                      }}>
                    {props.title}
                </Text>
                <Text mt="24px"
                      alignSelf={"start"}
                      style={{
                          fontFamily: 'Roboto',
                          fontSize: "16px",
                          lineHeight: "24px",
                          marginTop: "24px",
                          fontWeight: 400,
                          color: "sportJacket",
                          transition: "color 200ms ease"
                      }}
                      _groupHover={{
                          color: "eggshell.100",
                      }}
                >
                    {props.body}
                </Text>
                <ArrowIcon alignSelf={"start"} mt="24px" color="ideaYellow.100" width="24px" height="24px"/>
            </VStack>
        </Box>
    )
}