import {Menu, SCHeader} from "../components/SCHeader";
import {Fade, HStack, VStack} from "@chakra-ui/react";
import {SCButton} from "../components/SCButton";
import {SCFooter} from "../components/SCFooter";
import {Body, H1, H2} from "../components/Typography";
import {useHistory} from "react-router-dom";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {SnyderFlagIcon} from "../components/Icons";
import {HighlightValue} from "../components/HighlightValue";
import {PlanningCard} from "../components/Cards/PlanningCard";
import {DevelopmentCard} from "../components/Cards/DevelopmentCard";
import {SupportCard} from "../components/Cards/SupportCard";
import {useWindowSize} from "../utils/UseWindowSize";
import {about, common} from "../Copy";
import {logEvent} from "firebase/analytics";
import {OpenAICard} from "../components/Cards/OpenAICard";

export const About = (props) => {
    const history = useHistory()
    const navigateToContactPage = useCallback(() => history.push('/contact'), [history]);
    const navigateToPlanningPage = useCallback(() => history.push('/services/planning'), [history]);
    const navigateToDevelopmentPage = useCallback(() => history.push('/services/development'), [history]);
    const navigateToSupportPage = useCallback(() => history.push('/services/support'), [history]);
    const navigateToOpenAIPage = useCallback(() => history.push('/services/open-ai'), [history]);
    const [menuOpen, setMenuOpen] = useState(false)

    const [width] = useWindowSize()

    const padding = (width - 1140) / 2

    const [animate, setAnimate] = useState(false)
    const numberCounterRef = useRef(null);
    const numberCounterRefMobile = useRef(null);
    const isNumberCounterInViewport = useIsInViewport(width > 1200 ? numberCounterRef : numberCounterRefMobile);

    useEffect(() => {
        logEvent(props.analytics, "page_view", {page_location: "/about", page_title: "About"})

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //Source: https://bobbyhadz.com/blog/react-check-if-element-in-viewport
    function useIsInViewport(ref) {
        const [isIntersecting, setIsIntersecting] = useState(false);

        const observer = useMemo(
            () =>
                new IntersectionObserver(([entry]) =>
                    setIsIntersecting(entry.isIntersecting),
                ),
            [],
        );

        useEffect(() => {
            observer.observe(ref.current);

            return () => {
                observer.disconnect();
            };
        }, [ref, observer]);

        return isIntersecting;
    }

    useEffect(() => {
        if (isNumberCounterInViewport && !animate) {
            setAnimate(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNumberCounterInViewport])

    const DesktopLayout = () => {
        return (
            <VStack minH={"100vh"} h={"100%"} bg="eggshell.100" px={padding + "px"} spacing={"24px"}>
                <SCHeader path={"/about"}/>
                <VStack spacing={"40px"} pt={"78px"} alignSelf={"start"} alignItems={"start"} width={"100%"}>
                    <H1>About Us</H1>
                    <HStack display={"flex"} pt={"116px"} ps={"93px"} justifyContent={"space-between"} w={"100%"}>
                        <SnyderFlagIcon width="247" height="286"/>
                        <VStack h={"100%"} pt={"27px"} alignItems={"start"} alignSelf={"start"} maxW={"556px"}
                                spacing={"40px"}>
                            <H2>We're a dedicated team</H2>
                            <Body color={"sportJacket"}>
                                {about.body1}
                            </Body>
                            <Body color={"sportJacket"}>
                                {about.body2}
                            </Body>
                        </VStack>
                    </HStack>
                </VStack>
                {/*TODO use real values*/}
                <HStack ref={numberCounterRef} style={{marginTop: "172px"}} display={"flex"}
                        justifyContent={"space-evenly"}
                        w={"100%"}>
                    {/*TODO enable these once the numbers are higher*/}
                    <HighlightValue animate={animate} label={"Users Impacted"} value={2000000} suffix={"+"}
                                    autoConvert/>
                    <HighlightValue animate={animate} label={"Avg. Yrs of Experience"} value={7}/>
                    <HighlightValue animate={animate} label={"Hours Billed"} value={2560} autoConvert/>
                    <HighlightValue animate={animate} label={"Average Review"} value={5.0} decimalPoints={1}/>
                </HStack>
                <H2 paddingTop={"172px"}>Services</H2>
                <VStack paddingTop={"38px"} spacing={"24px"}>
                    <OpenAICard onClick={navigateToOpenAIPage}/>
                    <HStack spacing="24px">
                        <PlanningCard onClick={navigateToPlanningPage}/>
                        <DevelopmentCard onClick={navigateToDevelopmentPage}/>
                        <SupportCard onClick={navigateToSupportPage}/>
                    </HStack>
                </VStack>
                {/*TODO add clients section*/}
                <H2 paddingTop={"185px"}>Ready to Work Together?</H2>
                <Body width={"750px"} color={"sportJacket"}>
                    {common.contactUs}
                </Body>
                <SCButton style={{marginBottom: "162px"}} text={"Contact Us"} onClick={() => navigateToContactPage()}/>
                <SCFooter/>
            </VStack>
        )
    }

    const MobileLayout = () => {
        return menuOpen ? (
            <Fade in={menuOpen} animateOpacity>
                <Menu path={"/about"} setMenuOpen={setMenuOpen}/>
            </Fade>
        ) : (
            <VStack minH={"100vh"} h={"100%"} bg="eggshell.100" px={"24px"} spacing={"24px"}>
                <SCHeader isMobile setMenuOpen={setMenuOpen}/>
                <VStack spacing={"40px"} pt={"40px"} alignSelf={"start"} alignItems={"start"} width={"100%"}>
                    <H1>About Us</H1>
                    <HStack display={"flex"} spacing={"32px"} w={"100%"}>
                        <H2>We're a dedicated team</H2>
                        <SnyderFlagIcon style={{maxWidth: "119px"}}/>
                    </HStack>
                    <VStack h={"100%"} alignItems={"start"} alignSelf={"start"}
                            spacing={"40px"}>
                        <Body color={"sportJacket"}>
                            {about.body1}
                        </Body>
                        <Body color={"sportJacket"}>
                            {about.body2}
                        </Body>
                    </VStack>
                </VStack>
                TODO use real values
                <HStack ref={numberCounterRefMobile} style={{marginTop: "72px"}} display={"flex"}
                        justifyContent={"space-evenly"}
                        w={"100%"}>
                    <HighlightValue animate={animate} label={"Average Review"} value={5.0} decimalPoints={1} isMobile/>
                    <HighlightValue animate={animate} label={"Hours Billed"} value={2560} isMobile/>
                </HStack>
                <VStack spacing="24px" w={"100%"}>
                    <OpenAICard onClick={navigateToOpenAIPage} isMobile/>
                    <PlanningCard onClick={navigateToPlanningPage} isMobile/>
                    <DevelopmentCard onClick={navigateToDevelopmentPage} isMobile/>
                    <SupportCard onClick={navigateToSupportPage} isMobile/>
                </VStack>
                {/*TODO add clients section*/}
                <H2 paddingTop={"32px"} alignSelf={"start"}>Ready to Work Together?</H2>
                <Body color={"sportJacket"}>
                    {common.contactUs}
                </Body>
                <SCButton alignSelf={"start"}
                          text={"Contact Us"}
                          onClick={() => navigateToContactPage()}/>
                <SCFooter style={{marginTop: "72px"}} isMobile/>
            </VStack>
        )
    }

    return width > 1200 ? DesktopLayout() : MobileLayout()
}