import {Box, HStack, Text} from "@chakra-ui/react";
import {ArrowIcon} from "./Icons";

export const SCButton = props => {

    const {enabled, disabledText, ...rest} = props

    const EnabledButton = () => {
        return (
            <HStack spacing={0} className={"buttonContainer"} role={"group"} cursor={"pointer"} {...rest}>
                <Box px={"20px"}
                     height="48px"
                     bg="ideaYellow.100"
                     display={"flex"}
                     alignItems={"center"}
                     justifyContent={"center"}
                     _groupActive={{
                         bg: "ideaYellow.200"
                     }}
                >
                    <Text fontSize="20px" style={{fontFamily: 'Bebas Neue'}} as="u" whiteSpace={"nowrap"}>
                        {rest.text}
                    </Text>
                </Box>
                <>
                    <Box>
                        <Box bg="ideaYellow.100"
                             position={"absolute"}
                             width="1px"
                             height="48px"
                             display={"flex"}
                             alignItems={"center"}
                             justifyContent={"center"}
                             transformOrigin={"left center"}
                             sx={{
                                 '.buttonContainer &': {
                                     transitionProperty: "width",
                                     transitionDuration: "300ms"
                                 },
                                 '.buttonContainer:hover &': {
                                     width: "48px"
                                 },
                                 '.buttonContainer:active &': {
                                     bg: "ideaYellow.200"
                                 }
                             }}
                        >
                        </Box>
                        <Box width="48px" height="48px" borderRight="1px" borderTop="1px" borderBottom="1px"
                             borderColor="businessBlue" display={"flex"} alignItems={"center"}
                             justifyContent={"center"}>
                            <ArrowIcon color={"businessBlue"} zIndex={999} width="24px" height="24px"/>
                        </Box>
                    </Box>
                </>
            </HStack>
        )
    }

    const DisabledButton = () => {
        return (
            <HStack spacing={0} cursor={"not-allowed"} {...rest} onClick={null}>
                <Box px={"20px"}
                     height="48px"
                     bg="ideaYellow.200"
                     display={"flex"}
                     alignItems={"center"}
                     justifyContent={"center"}
                >
                    <Text fontSize="20px" style={{fontFamily: 'Bebas Neue'}} as="u" whiteSpace={"nowrap"}>
                        {disabledText ? disabledText : rest.text}
                    </Text>
                </Box>
                <>
                    <Box>
                        <Box bg="ideaYellow.200"
                             position={"absolute"}
                             width="48px"
                             height="48px"
                             display={"flex"}
                             alignItems={"center"}
                             justifyContent={"center"}
                        >
                        </Box>
                        <Box width="48px" height="48px" borderRight="1px" borderTop="1px" borderBottom="1px"
                             borderColor="businessBlue" display={"flex"} alignItems={"center"}
                             justifyContent={"center"}>
                            <ArrowIcon color={"businessBlue"} zIndex={999} width="24px" height="24px"/>
                        </Box>
                    </Box>
                </>
            </HStack>
        )
    }

    return enabled === false ? DisabledButton() : EnabledButton()
}