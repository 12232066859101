import {useWindowSize} from "../utils/UseWindowSize";
import {Box, HStack} from "@chakra-ui/react";

export const ShrinkBox = (props) => {
    const {isMobile, ...rest} = props
    const [width] = useWindowSize()
    const padding = isMobile ? 24 : (width - 1140) / 2

    return (
        <Box px={padding + "px"} width="100vw" boxShadow={"md"} justifyContent={"center"} {...rest}>
            <HStack alignItems={"center"} justifyContent={"space-between"}>
                {rest.children}
            </HStack>
        </Box>
    )
}
