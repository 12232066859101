import {Box, Input, SlideFade, Text, Textarea} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";

export const SCFormInput = (props) => {

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleKeyDown = (e) => {
        const buffer = e.key === "Backspace" ? 0 : 20

        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight + buffer}px`;
        // In case you have a limitation
        e.target.style.height = `${Math.max(e.target.scrollHeight, 176)}px`;

        window.scrollTo(0, scrollPosition)
    }

    return (
        <Box w={"100%"}>
            {!props.error && (
                <SlideFade in={props.value}>
                    <Text color={"gray"} ml={"16px"}>{props.title}</Text>
                </SlideFade>
            )}
            {props.error && (
                <SlideFade in={props.error}>
                    <Text color={"red.100"} ml={"16px"}>{props.error}</Text>
                </SlideFade>
            )}
            <Box
                h={(props.isTall || props.autoHeight) ? "176px" : "56px"}
                minH={props.isTall ? "176px" : null}
                maxH={props.autoHeight ? null : "350px"}
                style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    resize: props.autoHeight ? "none" : null
                }}
                justifyContent={"flex-start"}
                borderColor={"sportJacket"}
                color={"businessBlue"}
                value={props.value}
                onChange={(event) => props.onChange(event.target.value)}
                placeholder={props.title}
                as={(props.isTall || props.autoHeight) ? Textarea : Input}
                onKeyDown={props.autoHeight ? handleKeyDown : null}
            />
        </Box>
    )
}