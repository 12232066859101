import {ConsultingIcon} from "../Icons";
import {SCCard} from "../SCCard";

export const PlanningCard = (props) => {
    return (
        <SCCard title={"Planning"}
                body={"We will help with everything you need to get started with your product idea."}
                {...props}
        >
            <ConsultingIcon width={props.isMobile ? null : "114"} height={"185"}/>
        </SCCard>
    )
}