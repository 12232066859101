import {OpenAIIcon} from "../Icons";
import {SCCard} from "../SCCard";

export const OpenAICard = (props) => {
    return (
        <SCCard title={"Open AI / GPT-3.5 / GPT-4"}
                body={"Get ahead of the competition by building products with the state-of-the-art in Artificial Intelligence."}
                width={"100%"}
                {...props}
        >
            <OpenAIIcon width={props.isMobile ? null : "640"} height={props.isMobile ? "64" : null}/>
        </SCCard>
    )
}